<template>
    <div>
        <div class="row justify-between">
            <q-parallax >
                <template v-slot:media>
                    <img :src="bannerImg" />
                </template>

                <template v-slot:content="scope">
                    <div
                        class="absolute column items-center"
                        :style="{
                            top: scope.percentScrolled * 60 + '%',
                            left: 0,
                            right: 0,
                        }"
                    >
                        <div class="text-h3 text-white text-center">
                            {{ appName }}
                        </div>
                        <div class="text-h6 text-grey-3 text-center">
                            {{ tagLine }}
                        </div>
                    </div>
                </template>
            </q-parallax>
        </div>

        <h2 class="text-h2 text-weight-thin text-italic text-center">Apps</h2>

        <div class="row q-pa-lg">
            <div class="col-md-4 q-pa-lg" v-for="(app, index) in apps" :key="index">
                <q-card>
                    <q-img :src="app.imgUrl" />

                    <q-card-section>
                        <q-btn
                            fab
                            color="black"
                            icon="android"
                            class="absolute"
                            style="
                                top: 0;
                                right: 12px;
                                transform: translateY(-50%);
                            "
                            @click="openUrl(app.appUrl)"
                        />

                        <div class="row no-wrap items-center">
                            <div class="col text-h6 ellipsis">
                                {{ app.name }}
                            </div>
                        </div>

                        <!-- <q-rating v-model="stars" :max="5" size="32px" /> -->
                    </q-card-section>
                </q-card>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            appName: "",
            tagLine: "",
            bannerImg: "",
            apps: [],
        };
    },
    created() {
        fetch("https://differenttouchweb-default-rtdb.firebaseio.com/config.json")
            .then((response) => response.json())
            .then((data) => {
                this.appName = data.appName;
                this.tagLine = data.tagLine;
                this.bannerImg = data.bannerImg;
            });

        fetch("https://differenttouchweb-default-rtdb.firebaseio.com/apps.json")
            .then((response) => response.json())
            .then((data) => this.apps = data);
    },
    methods: {
        openUrl(url){
            window.open(url, '_blank')
        }
    }
};
</script>